<template>
    <v-container v-bind:class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <v-card class="rounded-lg overflow-hidden">
            <v-card-title>
                <v-icon left color="primary">mdi-calendar-clock</v-icon>
                Configuración de Horarios

                <v-spacer></v-spacer>
                <v-btn @click="editClasses = true" dark :loading="loading" class="mx-auto mr-2 rounded-lg">
                    <v-icon left>mdi-cog</v-icon>
                    Clases
                </v-btn>

                <v-dialog v-model="editClasses" max-width="600px">
                    <v-card>
                        <v-card-title>
                            Configuración de Clases
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="editClasses = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cerrar</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">

                                    <v-form ref="formClasses">
                                        <v-text-field v-model="type" label="Tipo" filled dense :rules="requiredRules"
                                            clearable></v-text-field>

                                    </v-form>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :color="color" block x-large v-bind="attrs" v-on="on">
                                                <v-icon left>mdi-palette</v-icon>
                                                Seleccionar color
                                            </v-btn>
                                        </template>
                                        <v-color-picker v-model="color" hide-inputs></v-color-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn block @click="addItem()" color="success" dark :loading="loading">
                                        <v-icon left>mdi-plus</v-icon>
                                        Agregar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-list dense>
                                        <v-list-item v-for="(item, index) in itemsTypes" :key="index">
                                            <v-list-item-avatar>
                                                <v-avatar :color="item.color" size="30">
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on" @click="removeItem(index)"
                                                            color="error" :loading="loading">
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar Clase</span>
                                                </v-tooltip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>



                <v-btn color="success" @click="storeSchedule(true)" dark :loading="loading" class="mx-auto rounded-lg">
                    <v-icon left>mdi-content-save</v-icon>
                    Guardar</v-btn>
            </v-card-title>
            <v-divider />

            <v-tabs v-model="tab">
                <v-tab>
                    <v-icon left>mdi-calendar-clock</v-icon>
                    Horarios fijos
                </v-tab>
                <v-tab>
                    <v-icon left>mdi-calendar-star</v-icon>
                    Dias Especiales
                </v-tab>

                <v-tab-item>
                    <v-divider />

                    <v-row>
                        <v-col cols="12">
                            <v-calendar class="rounded-lg ma-4 overflow-hidden" ref="calendar" type="week"
                                :events="calendarEvents" :event-color="getEventColor" :event-more="false"
                                :event-height="40" interval-minutes="60" interval-width="30"
                                :weekdays="[1, 2, 3, 4, 5, 6, 0]">


                                <template v-slot:day-header="{ date }">
                                    <v-btn x-small block @click.stop="openCreateEventDialog(date)"
                                        color="primary darken-2" class="rounded-md">
                                        <v-icon x-small left>mdi-plus</v-icon>
                                        {{ $vuetify.breakpoint.xs ? '' : 'Agregar' }}
                                    </v-btn>
                                </template>

                                <template v-slot:day-body="{ date, week }">
                                    <div class="v-current-time" :class="{ first: date === week[0].date }"
                                        :style="{ top: nowY }"></div>
                                </template>

                                <template v-slot:event="{ event }">
                                    <div class="px-2 fill-height" @click.stop="showEventDetails(event)">
                                        {{ event.name }}


                                        <template v-if="event && event.timed">
                                            <v-divider class="my-2"></v-divider>
                                            <div class="caption">
                                                {{ event.start.toLocaleTimeString([], {
                                                    hour: '2-digit', minute: '2-digit'
                                                })
                                                }}
                                                -
                                                {{ event.end.toLocaleTimeString([], {
                                                    hour: '2-digit', minute: '2-digit'
                                                })
                                                }}
                                            </div>

                                            <div class="caption">
                                                Intervalo
                                                {{ event.duration }} minutos

                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="caption">
                                                {{ event.start.toLocaleDateString('es-ES', {
                                                    month: '2-digit', day: '2-digit', year: 'numeric'
                                                })
                                                }}
                                            </div>
                                        </template>

                                    </div>
                                </template>
                            </v-calendar>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-divider />


                    <v-dialog v-model="futureModifications" max-width="400">
                        <v-card>
                            <v-card-title class="headline">
                                Agregar Cambio Futuro
                                <v-spacer></v-spacer>
                                <v-btn icon @click="futureModifications = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-title>

                            <v-card-text>
                                <v-form ref="form">
                                    <v-radio-group :rules="requiredRules" v-model="selectedDay.type" row hide-details
                                        class="mb-4">
                                        <v-radio label="Feriado" value="Feriado"></v-radio>
                                        <v-radio label="Dia Especial" value="Dia Especial"></v-radio>
                                        <v-radio label="Emergencia" value="Emergencia"></v-radio>
                                        <v-radio label="Evento" value="Evento"></v-radio>
                                    </v-radio-group>

                                    <v-divider v-show="selectedDay.type == 'Feriado'" class="mb-4"></v-divider>
                                    <v-radio-group v-model="selectedDay.isRecurrent" row
                                        v-show="selectedDay.type == 'Feriado'" hide-details class="mb-4">
                                        <v-radio label="No recurrente" :value="false"></v-radio>
                                        <v-radio label="Recurrente" :value="true"></v-radio>
                                    </v-radio-group>

                                    <v-text-field dense label="Titulo" :rules="requiredRules" filled hide-details
                                        class="mb-4" prepend-inner-icon="mdi-pencil"
                                        v-model="selectedDay.title"></v-text-field>

                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field filled hide-details class="mb-4"
                                                v-model="selectedDay.selectedDate" label="Selecciona fecha"
                                                prepend-inner-icon="mdi-calendar" readonly dense v-bind="attrs"
                                                :rules="requiredRules" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="selectedDay.selectedDate"
                                            @input="menu = false"></v-date-picker>
                                    </v-menu>

                                    <v-btn block color="primary" @click="addTimeRange"
                                        v-if="selectedDay.type == 'Dia Especial'" class="mt-4 mb-4">
                                        <v-icon left>mdi-plus</v-icon>
                                        Agregar clase
                                    </v-btn>

                                    <v-card v-for="(range, index) in selectedDay.timeRanges" :key="index"
                                        class="mb-4 pa-2" outlined>
                                        <v-row dense>
                                            <v-col cols="6">
                                                <v-autocomplete v-model="range.type" :items="itemsTypes"
                                                    item-text="name" item-value="name" label="Tipo" hide-details
                                                    :rules="requiredRules" dense filled></v-autocomplete>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-text-field label="Duración" v-model="range.duration" type="number"
                                                    hide-details :rules="requiredRules" dense filled />
                                            </v-col>

                                            <v-col cols="5">
                                                <v-text-field label="Hora Inicio" v-model="range.start" type="time"
                                                    hide-details :rules="requiredRules" dense filled />
                                            </v-col>
                                            <v-col cols="5">
                                                <v-text-field label="Hora Fin" v-model="range.end" type="time"
                                                    hide-details :rules="requiredRules" dense filled />
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn icon color="error" @click="removeTimeRange(index)">
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :loading="loading" color="success"
                                    @click="selectedDay.type == 'Feriado' ? saveHoliday() : saveSpecialDay()" dark>
                                    <v-icon left>mdi-content-save</v-icon>
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-card elevation="0">
                        <v-data-table :headers="headers" :items="specialDays" class="elevation-1" mobile-breakpoint="0"
                            @click:row="edit" sort-by="date" :items-per-page="10" :footer-props="{
                                'items-per-page-options': [5, 10, 15, -1],
                                'items-per-page-text': 'Filas por página:',
                            }">
                            <template v-slot:item.type="{ item }">
                                <v-chip :color="getColor(item)" dark small label>
                                    {{ item.type }}
                                </v-chip>
                            </template>

                            <template v-slot:item.timeRanges="{ item }">
                                <div class="d-flex flex-wrap gap-1">
                                    <v-chip v-for="(range, rangeIndex) in item.timeRanges" :key="rangeIndex"
                                        color="primary" small outlined class="mr-1 mb-1">
                                        <v-icon left small>mdi-clock-outline</v-icon>
                                        {{ range.start }} - {{ range.end }}
                                        <span class="mx-1">•</span>
                                        {{ range.type }}
                                    </v-chip>
                                </div>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-btn small icon @click.stop="removeSpecialDay(item)" color="error" class="mr-2">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn small icon @click.stop="edit(item)" color="primary">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>

                            <template v-slot:no-data>
                                <v-card-text class="text-center pa-5">
                                    <v-icon size="64" color="grey lighten-1">mdi-calendar-blank</v-icon>
                                    <div class="text-h6 grey--text text--darken-1 mt-3">
                                        No hay días especiales registrados
                                    </div>
                                    <div class="text-body-2 grey--text mt-2">
                                        Haga clic en el botón "Agregar" para crear uno nuevo
                                    </div>
                                </v-card-text>
                            </template>

                        </v-data-table>
                    </v-card>
                    <v-divider />

                    <v-card elevation="0">
                        <v-card-text class="text-center">
                            <v-btn color="primary" @click="futureModifications = true" rounded elevation="2"
                                class="px-6">
                                <v-icon left>mdi-plus</v-icon>
                                Agregar Día Especial
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <v-dialog v-model="showEventDialog" max-width="400">
            <v-card>
                <v-card-title>
                    Detalles del Evento
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showEventDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="selectedEvent">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ selectedEvent.name }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ selectedEvent.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
                                -
                                {{ selectedEvent.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider class="my-3"></v-divider>

                    <v-form v-if="isEditing" ref="eventForm">
                        <v-text-field v-model="editedEvent.start" label="Hora Inicio" type="time"
                            :rules="requiredRules"></v-text-field>
                        <v-text-field v-model="editedEvent.end" label="Hora Fin" type="time"
                            :rules="requiredRules"></v-text-field>
                        <v-text-field v-model="editedEvent.duration" label="Duración" type="number"
                            :rules="requiredRules"></v-text-field>
                        <v-autocomplete v-model="editedEvent.type" :items="itemsTypes" item-text="name"
                            item-value="name" label="Tipo" :rules="requiredRules"></v-autocomplete>
                    </v-form>
                </v-card-text>

                <v-card-actions v-if="selectedEvent && selectedEvent.timed">
                    <v-btn color="error" @click="deleteEvent" :loading="loading">
                        <v-icon left>mdi-delete</v-icon>
                        Eliminar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isEditing" color="primary" @click="startEditing">
                        <v-icon left>mdi-pencil</v-icon>
                        Editar
                    </v-btn>
                    <v-btn v-if="isEditing" color="success" @click="saveEventChanges" :loading="loading">
                        <v-icon left>mdi-content-save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- New Event Creation Dialog -->
        <v-dialog v-model="showCreateEventDialog" max-width="400">
            <v-card>
                <v-card-title>
                    Crear Horario Fijo
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showCreateEventDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-form ref="createEventForm">
                        <v-text-field v-model="newEvent.dayName" label="Día" readonly filled dense
                            :rules="requiredRules"></v-text-field>

                        <v-text-field v-model="newEvent.start" label="Hora Inicio" type="time" filled dense
                            :rules="requiredRules"></v-text-field>

                        <v-text-field v-model="newEvent.end" label="Hora Fin" type="time" filled dense
                            :rules="requiredRules"></v-text-field>

                        <v-text-field v-model="newEvent.duration" label="Duración (minutos)" type="number" filled dense
                            :rules="requiredRules"></v-text-field>

                        <v-autocomplete v-model="newEvent.type" :items="itemsTypes" item-text="name" item-value="name"
                            label="Tipo" filled dense :rules="requiredRules"></v-autocomplete>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="createEvent" :loading="loading">
                        <v-icon left>mdi-content-save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>


</template>
<script>

import { getFirestore, collection, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction } from "firebase/firestore";
import moment from 'moment';

export default {
    data() {
        return {
            selectedDaySlide: null,
            editClasses: false,
            itemsTypes: [
            ],
            days: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
            schedule: null,
            selectedDate: '',
            timeRanges: [],
            specialDays: [],
            menu: false,
            tab: 0,
            futureModifications: false,
            type: null,
            title: null,
            isRecurrent: false,

            requiredRules: [
                v => !!v || 'Este campo es requerido',
            ],
            loading: true,

            selectedDay: {
                date: null,
                timeRanges: [],
                title: null,
                type: null,
                isRecurrent: false,
            },


            color: 'grey darken-2',
            showEventDialog: false,
            selectedEvent: null,
            isEditing: false,
            editedEvent: {
                start: '',
                end: '',
                type: '',
                duration: null
            },
            nowY: 0,
            showCreateEventDialog: false,
            newEvent: {
                date: '',
                dayName: '',
                start: '',
                end: '',
                type: '',
                duration: null
            }
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Fecha',
                    align: 'start',
                    sortable: true,
                    value: 'date',
                },
                {
                    text: 'Tipo',
                    value: 'type',
                    sortable: false,
                },
                { text: 'Titulo', value: 'title' },
                { text: 'Acciones', value: 'actions', sortable: false },
            ]
        },
        calendarEvents() {
            if (!this.schedule) return []

            const events = []
            const today = moment()
            // Get week start from Monday
            const weekStart = moment(today).startOf('isoWeek')

            // Map our days array to actual day numbers (1-7 in moment, where 1 is Monday)
            const dayMapping = {
                "Lunes": 1,
                "Martes": 2,
                "Miercoles": 3,
                "Jueves": 4,
                "Viernes": 5,
                "Sabado": 6,
                "Domingo": 7
            }

            this.days.forEach((day) => {
                const dayNumber = dayMapping[day]
                const eventDate = moment(weekStart).add(dayNumber - 1, 'days')

                // Check if this date has a special day
                const dateString = eventDate.format('YYYY-MM-DD')
                const specialDay = this.specialDays.find(sd => sd.date === dateString)

                if (specialDay) {
                    if (specialDay.type === 'Feriado' || specialDay.type === 'Emergencia') {
                        events.push({
                            name: specialDay.title,
                            start: eventDate.clone().startOf('day').toDate(),
                            end: eventDate.clone().endOf('day').toDate(),
                            color: this.getSpecialDayColor(specialDay.type),
                            timed: false,
                        })
                    } else if (specialDay.ranges && specialDay.ranges.length > 0) {
                        specialDay.ranges.forEach(timeSlot => {
                            const [startHour, startMinute] = timeSlot.start.split(':').map(Number)
                            const [endHour, endMinute] = timeSlot.end.split(':').map(Number)

                            const start = eventDate.clone().hour(startHour).minute(startMinute).toDate()
                            const end = eventDate.clone().hour(endHour).minute(endMinute).toDate()

                            events.push({
                                name: `${specialDay.title} - ${timeSlot.type}`,
                                start,
                                end,
                                color: this.getEventColor(timeSlot),
                                timed: true,
                                duration: timeSlot.duration
                            })
                        })
                    }
                } else {
                    // Regular schedule handling
                    const daySchedule = this.schedule[day] || []
                    daySchedule.forEach(timeSlot => {
                        const [startHour, startMinute] = timeSlot.start.split(':').map(Number)
                        const [endHour, endMinute] = timeSlot.end.split(':').map(Number)

                        const start = eventDate.clone().hour(startHour).minute(startMinute).toDate()
                        const end = eventDate.clone().hour(endHour).minute(endMinute).toDate()

                        events.push({
                            name: timeSlot.type,
                            start,
                            end,
                            color: this.getEventColor(timeSlot),
                            timed: true,
                            duration: timeSlot.duration
                        })
                    })
                }
            })

            return events
        },
        cal() {
            return this.ready ? this.$refs.calendar : null
        },
    },
    mounted() {
        this.getScheduleSetup()


        //next check what day is today and select it on the slide
        let today = new Date().getDay();
        this.selectedDaySlide = today - 1;
        this.ready = true;

        this.$nextTick(() => {
            this.scrollToTime();
        })



    },
    methods: {
        update() {
            this.$forceUpdate()
        },
        getColor(item) {
            switch (item.type) {
                case 'Feriado':
                    return 'green';
                case 'Dia Especial':
                    return 'primary';
                case 'Emergencia':
                    return 'red';
                case 'Evento':
                    return 'grey';
            }
        },
        getColorRange(type) {

            if (!type) {
                return 'primary';
            }

            let item = this.itemsTypes.find(item => item.name == type.name);
            return item ? item.color : '';
        },
        addTimeRange() {
            this.selectedDay.timeRanges.push({
                start: '', end: '', type: null, duration: null

            });
        },
        removeTimeRange(index) {
            this.selectedDay.timeRanges.splice(index, 1);
        },
        async saveSpecialDay() {

            // Validate and save special day
            if (this.$refs.form.validate() == false) {
                return;
            }

            if (this.selectedDay.timeRanges.length == 0 &&
                this.type == 'Dia Especial'

            ) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Debe agregar al menos un rango de tiempo',
                    type: 'error'
                });
                return;
            }



            this.loading = true;


            // check if the selected date already exists and update it, if not, add it
            let exists = this.specialDays.find(day => day.date == this.selectedDay.selectedDate);
            if (exists) {
                exists.ranges = this.selectedDay.timeRanges;
                exists.title = this.selectedDay.title;
                exists.type = this.selectedDay.type;
            } else {
                this.specialDays.push({
                    type: this.selectedDay.type,
                    date: this.selectedDay.selectedDate,
                    ranges: this.selectedDay.timeRanges,
                    title: this.selectedDay.title,
                });

            }


            await this.storeSchedule();

            // Reset for next entry
            this.selectedDay.selectedDate = null;
            this.selectedDay.timeRanges = [];
            this.selectedDay.title = null;
            this.selectedDay.type = null;
            this.timeRanges = [];


        },
        async saveHoliday() {

            // Validate and save special day
            if (this.$refs.form.validate() == false) {
                return;
            }

            this.loading = true;


            // check if the selected date already exists and update it, if not, add it 
            let exists = this.specialDays.find(day => day.date == this.selectedDay.selectedDate);
            if (exists) {
                exists.title = this.selectedDay.title;
                exists.type = this.selectedDay.type;
                exists.recurrent = this.selectedDay.isRecurrent;
            } else {
                this.specialDays.push({
                    type: this.selectedDay.type,
                    date: this.selectedDay.selectedDate,
                    ranges: [],
                    title: this.selectedDay.title,
                    recurrent: this.selectedDay.isRecurrent
                });
            }
            await this.storeSchedule()


            // Reset for next entry
            this.selectedDay.selectedDate = null;
            this.selectedDay.timeRanges = [];
            this.selectedDay.title = null;
            this.selectedDay.type = null;
            this.timeRanges = [];
            this.isRecurrent = false;

        },

        async getScheduleSetup() {
            const db = getFirestore()
            const docRef = doc(db, `configurations/schedule`);
            const docSnap = await getDoc(docRef);
            let schedule = docSnap
            if (!schedule.exists()) {
                let schedule = {
                    Lunes: [],
                    Martes: [],
                    Miercoles: [],
                    Jueves: [],
                    Viernes: [],
                    Sabado: [],
                    Domingo: [],
                    specialDays: [],
                    classesConfiguration: []
                }
                setDoc(docRef, schedule);
            } else {
                this.schedule = schedule.data();
                this.itemsTypes = this.schedule.classesConfiguration;
                this.specialDays = this.schedule.specialDays.sort((a, b) => a.date - b.date) || [];
            }

            this.loading = false;
        },
        addSpecialDay(date, start, end) {
            this.specialDays.push({ date, start, end });
        },
        async removeSpecialDay(item) {

            let confirm = await this.$confirm("¿Está seguro que desea eliminar esta modificación?",
                {
                    color: "error",
                    title: "Eliminar Modificación",
                    icon: "mdi-alert-circle",
                    buttonTrueText: "Si",

                }
            );

            if (confirm) {
                let exist = this.specialDays.find(day => day.date == item.date);
                if (exist) {
                    this.specialDays.splice(this.specialDays.indexOf(exist), 1);
                }
                await this.storeSchedule();
            }

        },
        addScheduleRange(day) {
            this.schedule[day].push({ color: "primary", type: null, start: '', end: '' });
        },
        removeScheduleRange(day, index) {
            this.schedule[day].splice(index, 1);
        },
        async storeSchedule(confirmation = false) {

            if (confirmation) {
                let confirm = await this.$confirm("¿Está seguro que desea guardar los cambios en la agenda?<br><br>Se recomienda notificar a los usuarios de los cambios si los mismos afectan reservas ya realizadas.<br><br> Ya que si quedan fuera del rango de horarios disponibles, podrían ser eliminadas automáticamente por el sistema.",
                    {
                        color: "error",
                        title: "Advertencia",
                        icon: "mdi-alert-circle",
                        buttonTrueText: "Si estoy seguro",
                    });
            }


            this.loading = true;

            const db = getFirestore()
            const docRef = doc(db, `configurations/schedule`);
            //const docSnap = await getDoc(docRef);
            //let schedule = docSnap

            // Update schedule with specialDays before storing
            this.schedule.specialDays = this.specialDays;


            await setDoc(docRef, this.schedule);
            this.$notify({
                group: 'feedback',
                title: 'Éxito',
                text: 'Horario guardado con éxito',
                type: 'success'
            });

            this.loading = false;
            this.futureModifications = false;
        },
        makeAllDaysLikeThis(day) {
            let daySchedule = this.schedule[day]
            for (let i = 0; i < this.days.length; i++) {
                if (this.days[i] != day) {
                    this.schedule[this.days[i]] = JSON.parse(JSON.stringify(daySchedule))
                }
            }
        },
        edit(item) {
            this.selectedDay.selectedDate = item.date;
            this.selectedDay.timeRanges = item.ranges;
            this.selectedDay.title = item.title;
            this.selectedDay.type = item.type;
            this.selectedDay.isRecurrent = item.recurrent;

            this.futureModifications = true;

        },
        addItem() {
            if (this.$refs.formClasses.validate() == false) {
                return;
            }

            if (!this.color) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Debe elegir un color para la clase',
                    type: 'error'
                });
                return;
            }

            this.itemsTypes.push({ name: this.type, color: this.color });

            this.$refs.formClasses.reset();
            this.color = 'grey darken-2';
            this.storeSchedule();
        },
        removeItem(index) {
            this.itemsTypes.splice(index, 1);

            this.storeSchedule();
        },
        getEventColor(event) {
            const typeConfig = this.itemsTypes.find(type =>
                type.name === (typeof event.type === 'string' ? event.type : event.name)
            )
            return typeConfig ? typeConfig.color : 'primary'
        },
        getSpecialDayColor(type) {
            switch (type) {
                case 'Feriado':
                    return 'success'
                case 'Dia Especial':
                    return 'warning'
                case 'Emergencia':
                    return 'error'
                case 'Evento':
                    return 'secondary'
                default:
                    return 'warning'
            }
        },
        showEventDetails(event) {
            this.selectedEvent = event;
            this.showEventDialog = true;
            this.isEditing = false;

            // Convert event times to HH:mm format for the form
            this.editedEvent = {
                start: event.start.toTimeString().substring(0, 5),
                end: event.end.toTimeString().substring(0, 5),
                type: event.name,
                duration: event.duration
            };
        },
        startEditing() {
            this.isEditing = true;
        },
        async saveEventChanges() {
            if (!this.$refs.eventForm.validate()) return;

            this.loading = true;

            try {
                const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
                const dayName = dayNames[this.selectedEvent.start.getDay()];

                const daySchedule = this.schedule[dayName];
                const eventIndex = daySchedule.findIndex(slot =>
                    slot.start === this.selectedEvent.start.toTimeString().substring(0, 5) &&
                    slot.type === this.selectedEvent.name
                );

                if (eventIndex !== -1) {
                    daySchedule[eventIndex] = {
                        start: this.editedEvent.start,
                        end: this.editedEvent.end,
                        type: this.editedEvent.type,
                        duration: this.editedEvent.duration
                    };

                    await this.storeSchedule();

                    this.$notify({
                        group: 'feedback',
                        title: 'Éxito',
                        text: 'Evento actualizado correctamente',
                        type: 'success'
                    });

                    this.showEventDialog = false;
                    this.isEditing = false;

                    // Force a refresh of the calendar events
                    this.$nextTick(() => {
                        this.refreshCalendar();
                        // Also force a reactive update of the schedule
                        this.schedule = { ...this.schedule };
                    });
                }
            } catch (error) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error al actualizar el evento',
                    type: 'error'
                });
            } finally {
                this.loading = false;
            }
        },
        refreshCalendar() {
            if (this.$refs.calendar) {
                this.$refs.calendar.checkChange();
            }
        },
        getCurrentTime() {
            return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
        },
        scrollToTime() {
            this.$nextTick(() => {

                const now = moment()
                this.cal.scrollToTime({ hour: now.hour(), minute: now.minute() })
                this.nowY = this.cal.timeToY({ hour: now.hour(), minute: now.minute() }) + 'px'


            })
        },
        openCreateEventDialog(date) {
            const dayNames = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']

            this.newEvent = {
                date: new Date(date).toISOString().split('T')[0],
                dayName: dayNames[new Date(date).getDay()],
                start: '',
                end: '',
                type: '',
                duration: null
            }
            this.showCreateEventDialog = true
        },

        async createEvent() {
            if (!this.$refs.createEventForm.validate()) return

            this.loading = true
            try {
                const dayName = moment(this.newEvent.date).format('dddd')
                const translatedDays = {
                    'Monday': 'Lunes',
                    'Tuesday': 'Martes',
                    'Wednesday': 'Miercoles',
                    'Thursday': 'Jueves',
                    'Friday': 'Viernes',
                    'Saturday': 'Sabado',
                    'Sunday': 'Domingo'
                }

                const day = translatedDays[dayName]

                if (!this.schedule[day]) {
                    this.schedule[day] = []
                }

                this.schedule[day].push({
                    start: this.newEvent.start,
                    end: this.newEvent.end,
                    type: this.newEvent.type,
                    duration: parseInt(this.newEvent.duration)
                })

                await this.storeSchedule()

                this.$notify({
                    group: 'feedback',
                    title: 'Éxito',
                    text: 'Evento creado correctamente',
                    type: 'success'
                })

                this.showCreateEventDialog = false
                this.$nextTick(() => {
                    this.refreshCalendar()
                })
            } catch (error) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error al crear el evento',
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },
        async deleteEvent() {
            try {
                const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
                const dayName = dayNames[this.selectedEvent.start.getDay()];

                const daySchedule = this.schedule[dayName];
                const eventIndex = daySchedule.findIndex(slot =>
                    slot.start === this.selectedEvent.start.toTimeString().substring(0, 5) &&
                    slot.type === this.selectedEvent.name
                );

                if (eventIndex !== -1) {
                    daySchedule.splice(eventIndex, 1);
                    await this.storeSchedule();

                    this.$notify({
                        group: 'feedback',
                        title: 'Éxito',
                        text: 'Evento eliminado correctamente',
                        type: 'success'
                    });

                    this.showEventDialog = false;
                }
            } catch (error) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error al eliminar el evento',
                    type: 'error'
                });
            }
        },
    },
}
</script>
<style lang="scss">
.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
    }
}

.v-event-timed {
    overflow: hidden;
}

.v-calendar-daily_head-day-label {
    display: none;
}
</style>